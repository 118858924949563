.container {
  margin-top: 2vh;
  background-color: #343a40;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.text {
  width: 80%;
  text-align: justify;
  color: white;
}

.mores {
  color: wheat;
}

a {
  color: white;
}

@media only screen and (min-width: 850px) {
  .projectCard h4 {
    font-size: 1.3vw;
  }
}
