.card {
  filter: grayscale(100%);
  color: white;
  margin-bottom: 6vh;
}

.card img {
  width: 100%;
}

.card h5,
.card p {
  margin: 1vh 0;
}

.showcase {
  margin: 2vh 4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.showcase h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: x-large;
}

.projectCard {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  margin-bottom: 4vh;
}

.projectCard img {
  border-radius: 4px;
  width: 100%;
}

.projectCard h4,
.projectCard h6 {
  margin: 2vh 1vw;
}

.projectCard h4 {
  font-weight: 600;
}
.projectCard h6 {
  font-weight: 500;
  font-size: medium;
}

.projects {
  display: flex;
  flex-direction: row;
  margin: 2vh 4vw;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (min-width: 660px) {
  .projects {
    justify-content: space-evenly;
  }

  .projectCard {
    width: 30vh;
  }

  .projectCard {
    margin: 2vh;
  }

  .projectCard h4 {
    font-size: 2vw;
  }
}

@media only screen and (min-width: 850px) {
  .projectCard h4 {
    font-size: 1.3vw;
  }
}

.projectCard:hover {
  cursor: pointer;
}
