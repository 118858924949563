.navbar-nav li > a {
  color: #ffad00;
}

.navbar-nav li > a:hover {
  color: #333;
}

.navbar-nav li > a:after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: width 0.3s;
}

.navbar-nav li > a:hover::after {
  width: 100%;
  transition: width 0.3s;
}
