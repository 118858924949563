.video {
  width: 100%;
  height: auto;
}

.container {
  margin: 8vw;
}

.heading {
}

.h6Text {
  font-size: 5vw;
  font-weight: 400;
  text-align: center;
  margin-bottom: 6vh;
}

.cards {
  display: flex;
  flex-direction: column;
}

.cardManager {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image_container {
  position: relative;
  margin: 2vh 0;
}

.image_container img {
  max-width: 100%;
  height: auto;
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay:hover {
  opacity: 1;
}

.overlay p {
  text-align: center;
  text-align: justify;
  width: 80%;
  font-size: 4vw;
}

.projects {
  background-color: #383838;
  padding: 4vh 6vw;
  color: white;
  font-family: "Oswald", sans-serif;
  display: flex;
  justify-content: left;
  flex-direction: column;
  text-align: left;
  position: relative;
  z-index: 1;
}

.head {
  padding-left: 10vw;
  margin-bottom: 4vh;
}

.projects h2 {
  flex: 0;
  font-size: 6vw;
  width: 40%;
  margin-bottom: 0vh;
}

.hr {
  border-color: #686868;
  border-style: solid;
  border-width: 0 0 1px;
  height: 10px;
  line-height: 20px;
  text-align: left;
  width: 100%;
  margin-bottom: 4vh;
}

.button {
  width: 50%;
  font-size: 5vw;
  padding: 2vw;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-family: "Oswald", sans-serif;
  box-shadow: none;
  border: none;
}

.button:hover {
  background-color: #ffcb00;
  transition: all 1s ease-in-out;
  text-decoration: none;
  color: black;
}

.projects_card {
  display: flex;
  flex-direction: column;
}

.recentCard {
  position: relative;
  margin-bottom: 4vh;
}

.recentCard img {
  border-radius: 5px;
}

.recentCard h4 {
  font-size: 6vw;
  margin: 0;
  font-weight: 400;
  margin-bottom: 1vh;
}

.recentCard h6 {
  font-size: 5vw;
  margin: 0;
  font-weight: 400;
}

.hider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: yellow;
}
.recentCard:hover .hider {
  opacity: 0.3;
}

@media only screen and (min-width: 700px) {
  .h6Text {
    font-size: 3.6vw;
  }

  .overlay p {
    font-size: 2.6vw;
  }

  .projects h2 {
    font-size: 3.6vw;
  }
  .button {
    font-size: 2.6vw;
    width: 30%;
    padding: 1.6vw;
  }

  .recentCard {
    transform: scale(0.7);
    margin-bottom: 2vh;
    flex: 1;
  }

  .recentCard h4 {
    font-size: 4.6vw;
  }

  .recentCard h6 {
    font-size: 3.6vw;
  }

  .projects_card {
    flex-direction: row;
  }
}

@media only screen and (min-width: 950px) {
  .h6Text {
    font-size: 3vw;
  }
  .overlay p {
    font-size: 2vw;
  }

  .image_container {
    width: 40%;
  }

  .cardManager {
    justify-content: space-around;
  }

  .projects {
    padding: 2vh 2vw;
    flex-direction: row;
    align-items: center;
  }

  .head {
    padding-left: 6vw;
    flex: 1;
  }

  .projects_card {
    flex: 3;
  }
  .projects h2 {
    font-size: 3.6vw;
    width: 100%;
  }
  .button {
    font-size: 2vw;
    width: 60%;
  }

  .recentCard h4 {
    font-size: 4.6vw;
  }

  .recentCard h6 {
    font-size: 3.6vw;
  }
}

@media only screen and (min-width: 1100px) {
  .projects {
    padding: 4vh 2vw;
  }
  .projects h2 {
    font-size: 3vw;
  }
  .button {
    font-size: 1.8vw;
    width: 70%;
  }
  .recentCard h4 {
    font-size: 3vw;
  }
  .recentCard h6 {
    font-size: 2vw;
  }

  .hr {
    width: 90%;
  }
  .recentCard {
    transform: scale(0.8);
    margin-bottom: 2vh;
    flex: 1;
  }
}

@media only screen and (min-width: 1250px) {
  .h6Text {
    font-size: 2.4vw;
  }
  .image_container {
    width: 38%;
  }

  .overlay p {
    font-size: 1.6vw;
  }

  .button {
    font-size: 1.6vw;
    width: 70%;
  }
}
