.iframe {
  height: 60vh;
}

.container {
  max-width: 30%;
  margin: 2vh 4vw;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 9px solid rgb(255, 179, 0);
  background-color: #dededeba;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  height: 6vh;
}

textarea {
  height: 100px;
  resize: vertical;
}

.error {
  color: red;
  margin-bottom: 10px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Responsiveness */
@media (max-width: 768px) {
  .container {
    max-width: 90%;
  }
}

.main {
  display: flex;
  flex-direction: column;
  margin: 4vh 0;
  /* align-items: center; */
}

.branchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.branch {
  width: 80%;
  margin: 4vh 0;
}

.branch h5 {
  margin: 0;
  font-size: medium;
}

.branch hr {
  width: 100%;
  border: 0.5px solid black;
}

@media only screen and (min-width: 750px) {
  .main {
    display: flex;
    flex-direction: row;
    margin: 4vh 0;
    justify-content: center;
    /* align-items: center; */
  }

  .container {
    width: 50%;
  }
}
