.slider {
  width: 100%;
  max-height: 100vh;
}

.responsive_image {
  height: auto;
  max-width: 100%;
  width: 100%;
  object-fit: contain;
}

.warn {
  padding: 2vw;
  color: red;
  display: none;
}

@media (max-width: 900px) {
  .responsive_image {
    width: 100%; /* Use 100% of the width */
    height: 70vh; /* Set the height to 90% of the viewport height */
    object-fit: fill;
  }
}

@media (max-width: 600px) {
  .warn {
    display: block;
  }
}

.hover:hover {
  cursor: pointer;
}
