.container {
  display: flex;
  flex-direction: column;
}

.hero img {
  height: 100%;
}

.aboutUs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 2vh 6vw;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.aboutUs h2 {
  margin: 0;
  font-weight: 400;
  font-size: x-large;
}

.about_para {
  text-align: justify;
  margin: 2vh 0;
}

.about_heading {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: medium;
  margin: 2vh 0;
}

.leadership {
  background-color: #403e3e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 2vh 4vw;
}

.leadership h4 {
  font-weight: 500;
  color: #ffcb00;
}

.card {
  filter: grayscale(100%);
  color: white;
  margin-bottom: 6vh;
}

.card img {
  width: 100%;
}

.card h5,
.card p {
  margin: 1vh 0;
}

.showcase {
  margin: 2vh 4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.showcase h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: x-large;
}

.projectCard {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  margin-bottom: 4vh;
}

.projectCard:hover {
  cursor: pointer;
}

.projectCard img {
  border-radius: 4px;
  width: 100%;
}

.projectCard h4,
.projectCard h6 {
  margin: 2vh 1vw;
}

.projectCard h4 {
  font-weight: 600;
}
.projectCard h6 {
  font-weight: 500;
  font-size: medium;
}

@media only screen and (min-width: 500px) {
  .leader_cards {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .card img {
    width: 28vh;
  }
}

@media only screen and (min-width: 750px) {
  .leader_cards {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .card img {
    width: 40vh;
  }

  .projectCard img {
    width: 80vh;
  }
}

@media only screen and (min-width: 850px) {
  .about_cards {
    display: flex;
    flex: row;
    justify-content: space-between;
    align-items: start;
  }

  .aboutUs {
    margin: 6vh 2vw;
  }

  .about_para {
    margin: 2vh 0.9vw;
  }

  .about_card {
    width: 33%;
  }

  .showcase {
    margin: 6vh 4vw;
  }
}

@media only screen and (min-width: 950px) {
  .card img {
    width: 50vh;
  }

  .projects {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .projectCard img {
    width: 100%;
  }

  .projectCard {
    width: 30vw;
    margin: 0 1vw;
  }
}

@media only screen and (min-width: 1100px) {
  .projectCard {
    width: 25vw;
    margin: 0 1vw;
  }

  .about_para {
    font-size: large;
  }
}
